import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import '../node_modules/bulma/css/bulma.css';
import { Outils } from './mixins/outils'

Vue.config.productionTip = false

Vue.mixin(Outils);

window.axios = axios.create({
	baseURL: process.env.VUE_APP_API
});

window.axios.interceptors.request.use((config) => {
	let form_data = new FormData();

	for ( var key in config.data ) {
		form_data.append(key, typeof config.data[key] == 'object' ? JSON.stringify(config.data[key]) : config.data[key]);
	}

	config.data = form_data;
	return config;
});


Vue.component('Navbar',() => import('@/components/Navbar.vue'));
Vue.component('Abonnement',() => import('@/components/Abonnement.vue'));
Vue.component('Client',() => import('@/components/Client.vue'));
Vue.component('Message',() => import('@/components/Message.vue'));
Vue.component('Login',() => import('@/views/Login.vue'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
