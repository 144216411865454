import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		connected:false,
		client:{}
	},
	mutations: {
		setConnected(state, client) {
			state.connected=client? true : false;
			state.client=client;
		}
	},
	actions: {
	},
	modules: {
	},
	plugins: [vuexLocal.plugin]
})

