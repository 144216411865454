export const Outils = {
	mounted() {

	},
	computed: {
		apiDocUrl() {
			return process.env.VUE_APP_API+'doc';
		},
		today() {
			let d = new Date();

			let m = (d.getMonth() + 1);
			m = m < 10 ? '0'+m : m;

			let j = d.getDate();
			j = j < 10 ? '0'+j : j;
			
			return d.getFullYear() + "-" + m + "-" + j;

		},
		lastYear() {
			let d = new Date();
			d.setFullYear(d.getFullYear()-1);

			let m = (d.getMonth() + 1);
			m = m < 10 ? '0'+m : m;
			
			let j = d.getDate();
			j = j < 10 ? '0'+j : j;
			
			return d.getFullYear() + "-" + m + "-" + j;
		}
	},
	methods: {
		seDeconnecter() {
			this.$store.commit('setConnected',false);
		},
		lienMahalo(client) {
			return 'https://mahalo-app.io/publisher/654/company/1/title/null/contacts/'+client.codeClient
		},
		formatAdresse(client) {
			let html = [];
			for(let i=0;i<8;i++) {
				if(client['adresseNormalisee'+i]) {
					html.push(client['adresseNormalisee'+i]);
				}
			}

			return html.join('<br>');
		},
		striphtml(value) {
			let div = document.createElement("div");
			div.innerHTML = value;
			let text = div.textContent || div.innerText || "";
			return text;
		}
	}
}

